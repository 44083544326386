import React from "react"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"
import { RiHome2Line } from "react-icons/ri"

// Components
import { Link, graphql } from "gatsby"

const years = ({ pageContext, data }) => {
  const { year } = pageContext

  const posts = data.allMarkdownRemark.edges
  .filter(edge => !!edge.node.frontmatter.date)
  .map(edge =>
    <PostCard key={edge.node.id} data={edge.node} />
  )

  return (
    <Layout className="blog-page">
      <SEO
      title={`${year}年 | アーカイブ`}
      description={`${year}年 | アーカイブ`}
      />
    <h1 className="taxonomy-header">{year}年の記事</h1>
      <div className="grids col-1 sm-2 lg-3">
          {posts}
      </div>

      <div className="back-to-home">
      <Link to="/"><RiHome2Line />サイトトップへ</Link>
      </div>
      {/* <Link to="/cat/">カテゴリー一覧</Link> */}
      </Layout>
  )
}

export default years

export const pageQuery = graphql`
query($startYear: Date, $endYear: Date) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: { date: { gte: $startYear, lt: $endYear }}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            slug
            title
            year: date(formatString: "YYYY")
            month: date(formatString: "MM")
						featuredImage {
              publicURL
							childImageSharp {
								fluid(maxWidth: 540, maxHeight: 304, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
							}
						}
          }
        }
      }
    }
  }
`